
const presetConfigs = {
  default: {
    ressorts: [],
    yellow: 0,
    goal: 0
  },
  sport: {
    display: "Sport",
    ressorts: [989,958,975,982,942,747,938,939,1171,938,953,1039,972,1020,962,1004,997,985,10768,10742,1648965,1043,1037,1035,1542387,1752204,1730058,2269153,2330006,2332924,2411186,2418630,2418483,2418567,1865921,2432505,10742,2748915],
    yellow: 5500,
    goal: 70000
  },
  auto: {
    display: "Auto",
    ressorts: [284],
    yellow: 5500,
    goal: 70000
  },
  adabei: {
    display: "Adabei",
    ressorts: [96],
    yellow: 5500,
    goal: 70000
  },
  welt: {
    display: "Welt",
    ressorts: [90],
    yellow: 5500,
    goal: 70000
  },
  oesterreich: {
    display: "Österreich",
    ressorts: [102,305,136],
    yellow: 5500,
    goal: 70000
  },
  wirtschaft: {
    display: "Wirtschaft",
    ressorts: [136],
    yellow: 5500,
    goal: 70000
  },
  gesundheit: {
    display: "Gesundheit",
    ressorts: [650,675],
    yellow: 5500,
    goal: 70000
  },
  gesund: {
    display: "Gesund",
    ressorts: [650,2491827,2491839,2491835,2491819,2506026],
    yellow: 5500,
    goal: 70000
  },
  wien: {
    display: "Wien",
    ressorts: [714,2468518,2468537,2468540,2468576,2468586,2468593,2468598,2954062,3282887],
    yellow: 5500,
    goal: 70000
  },
  noe: {
    display: "Niederösterreich",
    ressorts: [725,2837443,2837462,2837467,2837478,2837488,2837497,2848584,2872419,2989750,3284549],
    yellow: 5500,
    goal: 70000
  },
  bgld: {
    display: "Burgenland",
    ressorts: [739,2888084,2888093,2888099,2888100,2888104,2888107,2929739,3244033],
    yellow: 5500,
    goal: 70000
  },
  ooe: {
    display: "Oberösterreich",
    ressorts: [735,2871314,2871330,2871339,2871361,2871363,2871369],
    yellow: 5500,
    goal: 70000
  },
  sbg: {
    display: "Salzburg",
    ressorts: [733,1742366,1921415,3253252,3253330,3253412,3253423],
    yellow: 5500,
    goal: 70000
  },
  tirol: {
    display: "Tirol",
    ressorts: [729,2887387,2887404,2887408,2887411,2887422,2887423,2969570,3049856],
    yellow: 5500,
    goal: 70000
  },
  vbg: {
    display: "Vorarlberg",
    ressorts: [731,3282963,3306314,3306322,3306325,3306339,3477808],
    yellow: 5500,
    goal: 70000
  },
  stmk: {
    display: "Steiermark",
    ressorts: [727,2172782,2552296,2863996,2891024,2891066,2891069,2891074,2891075,2891077,3328023],
    yellow: 5500,
    goal: 70000
  },
  ktn: {
    display: "Kärnten",
    ressorts: [737,2727634,2834487,2886586,2886704,2886710,2886728,2886735,3497936],
    yellow: 5500,
    goal: 70000
  },
  all_countys: {
    display: "Alle Bundesländer",
    ressorts: [
      // Wien
      714,2468518,2468537,2468540,2468576,2468586,2468593,2468598,2954062,3282887,
      // Niederösterreich
      725,2837443,2837462,2837467,2837478,2837488,2837497,2848584,2872419,2989750,3284549,
      // Burgenland
      739,2888084,2888093,2888099,2888100,2888104,2888107,2929739,3244033,
      // Oberösterreich
      735,2871314,2871330,2871339,2871361,2871363,2871369,
      // Salzburg
      733,1742366,1921415,3253252,3253330,3253412,3253423,
      // Tirol
      729,2887387,2887404,2887408,2887411,2887422,2887423,2969570,3049856,
      // Vorarlberg
      731,3282963,3306314,3306322,3306325,3306339,3477808,
      // Steiermark
      727,2172782,2552296,2863996,2891024,2891066,2891069,2891074,2891075,2891077,3328023,
      // Kärnten
      737,2727634,2834487,2886586,2886704,2886710,2886728,2886735,3497936
    ],
    yellow: 5500,
    goal: 70000
  }
};

 var lines = {
  "forecast_higher_than_4_week_average": [
    "🚀 On track for a record day! Let's keep it up! 💪",
    "🌟 Amazing progress! We're ahead of the game! 🌟",
    "🎉 Forecast is looking great! Keep pushing! 🚀",
    "✨ Keep up the fantastic work! We're soaring high! 🌠",
    "🔥 We're on fire today! Let's keep the momentum! 🔥",
    "🏆 We're outpacing the average! Let's make history! 🌟",
    "🚀 Incredible progress! We're ahead of the curve! 💪",
    "🌟 Shining bright! Let's reach new heights today! ✨",
    "🎉 Ahead of the game! Let's finish strong! 🚀",
    "✨ Keep going, we're set for a stellar day! 🌠",
    "🌠 Outstanding forecast! Let's keep climbing higher! 🌟",
    "🚀 We're ahead of our best pace! Let's keep it up! 💪",
    "🔥 Smashing records! Keep the energy high! 🎉",
    "🌟 We're above average! Let's stay the course! ✨",
    "🎉 Fantastic strides! We're leading the pack! 🚀",
    "✨ Unstoppable momentum! Let's reach for the stars! 🌠",
    "🏆 Surpassing expectations! Let's keep the fire burning! 🌟",
    "🚀 On a roll! We're setting new standards! 💪",
    "🌟 Keep up the stellar work! We're ahead of the curve! ✨",
    "🎉 Leading the way! Let's keep the momentum strong! 🚀"
  ],
  "actual_value_above_4_week_average": [
    "🔥 We're crushing it! Keep the momentum going! 🔥",
    "💪 Above average already! Let's make it an extraordinary day! 🌟",
    "🌟 Fantastic work! We're leading the way! 🌟",
    "🏆 Outstanding effort! We're ahead of the average! 🌟",
    "🎉 Great job! We're surpassing expectations! 🚀",
    "✨ Amazing performance! Let's keep shining! 🌠",
    "🌟 We're on top! Keep up the excellent work! 💪",
    "🔥 Blazing past the average! Let's keep it up! 🔥",
    "🌟 Above and beyond! Keep leading the charge! 🚀",
    "🏅 Exceptional work! We're ahead of the game! 🌠",
    "🌠 Incredible performance! We're exceeding the average! 🌟",
    "💪 Above and beyond! Let's keep this amazing pace! 🚀",
    "🔥 Exceptional results! We're on fire! 🔥",
    "🌟 Surpassing expectations! Let's keep it going! 🌠",
    "🏆 Leading the charge! We're above the norm! 🌟",
    "🎉 Outstanding progress! We're setting new records! 🚀",
    "✨ Incredible results! Let's keep shining bright! 🌟",
    "🌟 Unmatched performance! We're ahead of the game! 💪",
    "🔥 Breaking barriers! Keep up the amazing work! 🌠",
    "🏅 Above the rest! Let's keep up the stellar effort! 🚀"
  ],
  "actual_goal_reached": [
    "🎉 Goal achieved! We're unstoppable! Now, let's aim even higher! 🏆",
    "🏅 We did it! Celebrate our success and let's keep going! 🎊",
    "👏 Mission accomplished! Great job, team! Let's break new records! 🎉",
    "🌟 Goal reached! We're amazing! Let's set our sights on the next milestone! 🌟",
    "🎊 Success! We've hit the target! Let's aim for even greater heights! 🏅",
    "🏆 We made it! Outstanding work! Let's see how much further we can go! 🎉",
    "🎉 Victory! We've reached the goal! Now, let's surpass it! 🏆",
    "🌟 Achievement unlocked! Fantastic job! Let's keep the momentum going! 🌠",
    "🏅 We did it! Celebrate our success and push for more! 🎊",
    "👏 Goal met! Keep up the great work and let's go beyond! 🌟",
    "🎉 Goal achieved! Let's celebrate and aim higher! 🌟",
    "🏅 Fantastic work! We've reached our target! 🚀",
    "👏 Success is ours! Let's set new milestones! 🎉",
    "🌟 We've done it! Time to raise the bar! 🏆",
    "🎊 Goal met! Now, let's go even further! 🌟",
    "🏆 Incredible achievement! Let's keep pushing! 🎉",
    "🎉 Victory is ours! Let's aim for new heights! 🏅",
    "🌟 Goal accomplished! Let's continue this great work! 🚀",
    "🏅 Outstanding success! We're unstoppable! 🎉",
    "👏 We've reached the goal! Let's keep the momentum! 🌟"
  ],
  "missed": [
    "🫡 We were on the verge of greatness! we were this close! 🪖",
    "was below average 🥲",
    "🫡 Almost there! Let's hit it next time! 💪",
    "🥲 So close! Let's strive for better tomorrow!",
    "🪖 We gave it our all! Let's regroup and push harder! 🫡",
    "🥲 Just missed the mark! Let's aim for a comeback!",
    "🫡 Nearly there! Let's refocus and go again! 🪖",
    "🥲 A tough day, but we're ready for the next challenge!",
    "🫡 We were close! Let's finish strong tomorrow! 🪖",
    "🥲 Not quite, but our effort was commendable!",
    "🫡 We missed it, but let's bounce back stronger! 🪖",
    "🥲 It was a tough day, but we learned a lot! Let's do better!"
  ],
  "general_encouragement": [
    "🤞 It's not over until we win!",
    "🌈 Every step counts! Let's finish strong! 💪",
    "💫 Keep the energy high, we're almost there! 🚀",
    "🌟 Stay positive, success is within reach! 🌟",
    "✨ Keep pushing, greatness is ahead! 🌠",
    "💪 Don't stop now, we're so close! 🌟",
    "🌟 Keep the spirit high, we're nearly there! ✨",
    "🔥 Push through, success is near! 🚀",
    "🎉 Stay focused, we can achieve it! 🌟",
    "✨ Keep moving forward, victory is near! 🌠",
    "🤞 Keep pushing! We're almost there!",
    "🌈 Every effort counts! Let's finish with a bang! 💪",
    "💫 Keep striving, success is near! 🚀",
    "🌟 Stay motivated, we're close to our goal! 🌟",
    "✨ Keep the energy high, greatness awaits! 🌠",
    "💪 Don't give up, we're nearly at the finish line! 🌟",
    "🌟 Keep the momentum, success is just ahead! ✨",
    "🔥 Keep pushing, we're on the brink of victory! 🚀",
    "🎉 Stay focused, we can achieve greatness! 🌟",
    "✨ Keep moving forward, triumph is near! 🌠"
  ],
  "goal_reachable_forecast": [
    "🌟 The goal is within reach! Let's make it happen! 🚀",
    "✨ Almost there! Keep pushing towards the goal! 💪",
    "🎉 The goal is reachable! Keep the momentum strong! 🌟",
    "🚀 Just a little more effort to hit the goal! We got this! 💪",
    "🌟 So close to the goal! Let's finish strong! 🌠",
    "🎯 The target is in sight! Push through to reach it! 🚀",
    "✨ Keep the energy high, the goal is within grasp! 💪",
    "🔥 We're nearly there! Let's achieve the goal together! 🌟",
    "🏆 Almost at the finish line! Let's make it happen! 🚀 Don't tell me the sky is the limit when there are footprints on the moon! 🌕",
    "🌟 The goal is in sight! Let's make it happen! 🚀",
    "✨ So close! Keep pushing towards success! 💪",
    "🎉 We're almost there! Let's keep up the momentum! 🌟",
    "🚀 Just a bit more effort! We can do this! 💪",
    "🌟 The finish line is near! Let's give it our all! 🌠",
    "🎯 The goal is within reach! Push through to achieve it! 🚀",
    "✨ Keep the energy high, the target is near! 💪",
    "🔥 We're on the brink of success! Let's finish strong! 🌟",
    "🏆 Almost there! Let's reach the goal together! 🚀",
    "🌕 The sky is not the limit, keep pushing! 🌠"
  ]
}

 function getRandomText(key) {
    const randomIndex = Math.floor(Math.random() * lines[key].length);
    return lines[key][randomIndex];
}

module.exports = {
    getRandomText: getRandomText,
    presetConfigs: presetConfigs
}