import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";

import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// menu icons
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import SecurityIcon from "@material-ui/icons/Security";
import SubjectIcon from "@material-ui/icons/Subject";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import PublicIcon from "@material-ui/icons/Public";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

const presetConfigs = require("./shared_funcs").presetConfigs;


export default function Menu({ open, toggleOpen }) {
  // you can inject action buttons, see example in home/App.js
  const [menuActions, setMenuActions] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState({
    Articles: false,
    Pushes: false,
  });
  window.redscreen.setMenuActions = (arr) => {
    setMenuActions(arr);
  };

  const menuLinks = [
    {
      name: "Articles",
      icon: <FormatListNumberedIcon />,
      href: "/",
      subMenu: Object.entries(presetConfigs).map(([key, config]) => ({
        name: config.display,
        href: `/?filter=${JSON.stringify(config.ressorts)}`,
      })),
    },
    { name: "Videos", icon: <VideoLibraryIcon />, href: "/videos" },
    { name: "Bundesland", icon: <SecurityIcon />, href: "/bundesland" },
    { name: "Topcis", icon: <SecurityIcon />, href: "/topics" },
    { name: "News", icon: <SubjectIcon />, href: "/news" },
    { name: "Charts", icon: <ShowChartIcon />, href: "/charts" },
    { name: "Social Media", icon: <PublicIcon />, href: "/social" },
    {
      name: "Pushes 🇦🇹",
      icon: <NotificationsActiveIcon />,
      href: "/pushes?label=Pushes 🇦🇹",
      subMenu: [
        { name: "Pushes 🌍", href: "/pushes?label=International 🌍&group=INT" },
        { name: "Pushes 🇩🇪", href: "/pushes?label=Deutschland 🇩🇪&group=DE" },
      ],
    },
    { name: "Top Commented", icon: <ChatBubbleIcon />, href: "/topcommented" },
  ];

  const toggleCollapseOpen = (event, item) => {
    event.preventDefault();
    if (item.subMenu && item.subMenu.length > 0) {
      setCollapseOpen((c) => {
        c[item.name] = !c[item.name];
        return c;
      });
    }
  };

  return (
    <Drawer anchor="left" open={open} onClose={toggleOpen}>
      <h1 className="menu__header">
        <DesktopWindowsIcon /> REDSCREEN
      </h1>
      <Divider />

      <List>
        {menuLinks.map((item, idx) => {
          return (
            <React.Fragment>
              <Link to={item.href} key={idx} onClick={() => setMenuActions([])}>
                <ListItem button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    style={{ letterSpacing: 0 }}
                    primary={item.name}
                  />
                  {item.subMenu &&
                    item.subMenu.length > 0 &&
                    (collapseOpen[item.name] ? (
                      <ExpandLess
                        onClick={(e) => toggleCollapseOpen(e, item)}
                      />
                    ) : (
                      <ExpandMore
                        onClick={(e) => toggleCollapseOpen(e, item)}
                      />
                    ))}
                </ListItem>
              </Link>
              {item.subMenu && item.subMenu.length > 0 && (
                <Collapse
                  in={collapseOpen[item.name]}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.subMenu.map((subItem, subIdx) => {
                    return (
                      <Link
                        to={subItem.href}
                        key={subIdx}
                        onClick={() => setMenuActions([])}
                      >
                        <ListItem button>
                          <ListItemText
                            style={{ letterSpacing: 0 }}
                            primary={subItem.name}
                          />
                        </ListItem>
                      </Link>
                    );
                  })}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
      <Divider />

      <List>
        {menuActions.map((item, idx) => {
          return (
            <ListItem button onClick={item.onClick} key={idx}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
